
import Vue, { PropType } from "vue";

import http from "@/http";

import MyEditor from "@/components/station/MyEditor.vue";

import { Garbage, GarbageStation } from "@/interfaces";

export interface DataType {
  name: string;
  memo: string;
  lat: number;
  lng: number;
  garbage_id: number;
}

export default Vue.extend({
  components: {
    MyEditor
  },
  props: {
    garbage: {
      type: Object as PropType<Garbage>,
      required: true
    }
  },
  data(): DataType {
    return {
      name: "",
      memo: "",
      lat: 0,
      lng: 0,
      garbage_id: this.garbage.id ?? 0
    };
  },
  computed: {
    station(): GarbageStation {
      return {
        name: this.name,
        garbage: this.garbage,
        garbage_id: this.garbage_id,
        map: "",
        position: {
          coordinates: [+this.lng, +this.lat]
        }
      };
    }
  },
  mounted() {
    const { lat, lng } = this.$route.query;

    if (lat && lng) {
      this.lat = +lat;
      this.lng = +lng;
    } else {
      this.lat = 36.554878;
      this.lng = 136.636004;
    }
  },
  methods: {
    async save(e: GarbageStation) {
      const url = `garbages/${this.garbage.id}/stations`;

      const { data } = await http.post(url, {
        ...e
      });

      await this.$router.push(`/building/${this.garbage.building_id}/garbage`);
    }
  }
});
