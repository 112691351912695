
import Vue, { PropType } from "vue";

interface GoogleMapWindow extends Window {
  handleScript: Function;
  google: any;
}

declare const window: GoogleMapWindow;

export interface DataType {
  map: any;
  address: string;
}

export default Vue.extend({
  props: {
    google: {
      type: Object,
      required: true
    },
    lat: {
      type: Number as PropType<number>,
      required: true
    },
    lng: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): DataType {
    return {
      map: undefined,
      address: ""
    };
  },
  async mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const map = this.$el.querySelector("#googlemap");

      const { Map, MapTypeId } = this.google.maps;

      this.map = new Map(map, {
        zoom: 18,
        center: {
          lat: this.lat,
          lng: this.lng,
          mapTypeId: MapTypeId.ROADMAP
        }
      });
    }
  }
});
