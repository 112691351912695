
import Vue, { PropType } from "vue";

export interface DataType {
  marker: any;
}

export default Vue.extend({
  props: {
    google: {
      type: Object as PropType<any>,
      required: true
    },
    map: {
      type: Object as PropType<any>,
      required: true
    },
    lat: {
      type: Number as PropType<number>,
      required: true
    },
    lng: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): DataType {
    return {
      marker: undefined
    };
  },
  mounted() {
    const { Marker } = this.google.maps;

    this.marker = new Marker({
      position: {
        lat: this.lat,
        lng: this.lng
      },
      map: this.map,
      draggable: true
    });

    this.marker.addListener("dragend", () => {
      const position = this.marker.getPosition();

      this.$emit("dragend", {
        lng: position.lng(),
        lat: position.lat()
      });
    });
  }
});
