
import Vue, { PropType } from "vue";

const API_KEY = "AIzaSyDc-4qtErbJ-ZaxoXkKE4tu9mvwODA1r1M";

import MyGoogleMap from "@/components/MyGoogleMap.vue";
import MyMapMaker from "@/components/MyMapMaker.vue";

import { GarbageStation, Geocoder } from "@/interfaces";

import { mdiContentSave } from "@mdi/js";

import { Loader } from "@googlemaps/js-api-loader";

export interface DataType {
  google: any;
  name: string;
  memo?: string;
  lat: number;
  lng: number;
  valid: boolean;
  address: string;
  geocoding: boolean;
}

export interface LatLng {
  lat: number;
  lng: number;
}

export default Vue.extend({
  components: {
    MyGoogleMap,
    MyMapMaker,
  },
  props: {
    station: {
      type: Object as PropType<GarbageStation>,
      required: true,
    },
  },
  data(): DataType {
    return {
      google: undefined,
      name: "",
      memo: "",
      lat: 36.554878,
      lng: 136.636004,
      valid: false,
      address: "",
      geocoding: false,
    };
  },
  computed: {
    icon() {
      return { mdiContentSave };
    },

    rules() {
      return {
        name: [(v: string) => !!v || "名前は必須です"],
        lat: [(v: string) => !!v || "緯度は必須です"],
        lng: [(v: string) => !!v || "経度は必須です"],
      };
    },
  },
  async mounted() {
    const loader = new Loader({
      apiKey: API_KEY,
      version: "weekly",
    });

    const google = await loader.load();

    this.google = google;

    this.name = this.station.name;
    this.memo = this.station.memo;
    this.lat = this.station.position.coordinates[1];
    this.lng = this.station.position.coordinates[0];
  },
  methods: {
    async click() {
      this.geocoding = true;

      const geocoder = new this.google.maps.Geocoder();

      const request = {
        address: this.address,
      };

      const { results, status } = (await geocoder.geocode(
        request
      )) as Geocoder.Response;

      if (results.length > 0) {
        const result = results[0];

        const { location } = result.geometry;

        this.lat = location.lat();
        this.lng = location.lng();
      }

      this.geocoding = false;
    },
    async submit() {
      this.$emit("save", {
        name: this.name,
        memo: this.memo,
        lat: this.lat,
        lng: this.lng,
      });
    },
    dragend({ lat, lng }: LatLng) {
      this.lat = lat;
      this.lng = lng;
    },
  },
});
